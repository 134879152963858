export default (): void => {
  const admin = document.querySelector('*[data-admin]') as HTMLElement

  if (admin && !sessionStorage.getItem('admin') && sessionStorage.getItem('admin') !== 'active') {
    const currentTab = window.open('', '_self') as Window

    currentTab.document.write('')
    window.location.assign(window.location.origin + '/authorization.php')
  }
}
