import fancybox from './ts/fancybox'
import sliderSwiper from './ts/slider-swiper'
import yandexMap from './ts/yandex-map'
import smoothScroll from './ts/smooth-scroll'
import scrollHeader from './ts/scroll-header'
import currentTab from './ts/current-tab'
import sidebar from './ts/sidebar'
import scrollTo from './ts/scroll-to'
import animation from './ts/animation'
import waved from './ts/waved'
import movement from './ts/movement'
import listing from './ts/listing'
import accordion from './ts/accordion'
import submitHandler from './ts/submit-handler'
import dragAndDrop from './ts/drag-and-drop'
import choiceFile from './ts/choice-file'
import inputs from './ts/inputs'
import phoneMask from './ts/phone-mask'
import preloader from './ts/preloader'
import password from './ts/password'
import admin from './ts/admin'

import './scss/style.scss'

window.addEventListener('DOMContentLoaded', ((): void => {
  fancybox()
  sliderSwiper()
  yandexMap()
  currentTab()
  smoothScroll()
  scrollHeader()
  sidebar()
  scrollTo()
  animation()
  waved()
  movement()
  listing()
  accordion()
  submitHandler()
  dragAndDrop()
  choiceFile()
  inputs()
  phoneMask()
  preloader()
  password()
  admin()
}) as EventListener)
