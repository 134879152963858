import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs } from 'swiper'
import { media } from './functions/media'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

export default (): void => {
  new window.Swiper('.main-slider .swiper', {
    pagination: {
      el: '.main-slider .swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.main-slider .swiper-button-prev',
      nextEl: '.main-slider .swiper-button-next',
    },
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    speed: 1000,
    grabCursor: true,
    loop: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1,
      },
    },
    autoplay: {
      delay: 4000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper

  new window.Swiper('.stocks-slider .swiper', {
    pagination: {
      el: '.stocks-slider .swiper-pagination',
      clickable: true,
    },
    navigation: {
      prevEl: '.stocks-slider .swiper-button-prev',
      nextEl: '.stocks-slider .swiper-button-next',
    },
    slidesPerView: 1.2,
    slidesPerGroup: 1,
    spaceBetween: 20,
    speed: 1000,
    grabCursor: true,
    breakpoints: {
      [media.sm]: {
        slidesPerView: 1.5,
        spaceBetween: 28,
      },
      [media.md]: {
        slidesPerView: 1,
        spaceBetween: 32,
      },
    },
  }) as Swiper
}
