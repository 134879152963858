import { dialogOpen, dialogNotClosing, dialogClose } from './fancybox'
import { validation } from './functions/validation'

const submitHandler = (event: Event): void => {
  const form = event.target as HTMLFormElement

  switch (form.dataset.form) {
  case 'action': {
    if (!validation(form)) event.preventDefault()
    break
  }

  default: {
    event.preventDefault()

    if (!validation(form)) return

    const formData: FormData = new FormData(form)
    const submitBtn = form.querySelector('button[type="submit"]') as HTMLButtonElement
    const requestUrl = './ajax/submit-handler.php'

    const formDataObj = {
      login: '',
      password: '',
    }

    const constants = {
      login: 'admin',
      password: 'Daola9&5D3',
    }

    submitBtn.setAttribute('disabled', 'disabled')
    dialogNotClosing('./dialogs/dialog-preloader.php')

    switch (form.dataset.form) {
    case 'submit': {
      fetch(requestUrl, {
        method: 'POST',
        body: formData,
      })
        .then((response: Response): void => {
          response.text()
        })
        .then((): void => {
          dialogClose()
          dialogOpen('./dialogs/dialog-submit.php')
          form.reset()
          submitBtn.removeAttribute('disabled')
        })
        .catch((error: string): void => console.log('The form has not been sent', error))

      break
    }

    case 'authorization': {
      for (const [name, value] of formData) formDataObj[name] = value

      setTimeout((): void => {
        if (formDataObj.login === constants.login && formDataObj.password === constants.password) {
          sessionStorage.setItem('admin', 'active')
          window.location.assign(window.location.origin + '/admin.php')
        }

        dialogClose()
        form.reset()
        submitBtn.removeAttribute('disabled')
      }, 1000)

      break
    }

    case 'admin': {
      if (sessionStorage.getItem('admin') && sessionStorage.getItem('admin') === 'active') {
        fetch(requestUrl, {
          method: 'POST',
          body: formData,
        })
          .then((response: Response): void => {
            response.text()
          })
          .then((): void => {
            setTimeout((): void => window.location.reload(), 1000)
          })
          .catch((error: string): void => console.log('The form has not been sent', error))
      }

      break
    }
    }

    break
  }
  }
}

export default (): void => {
  document.addEventListener('submit', ((event: Event): void => {
    if ((event.target as HTMLFormElement).hasAttribute('data-form')) submitHandler(event)
  }) as EventListener)
}
